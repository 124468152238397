import { Severity } from '@sentry/react';
import { Divider, Form, Input, Typography } from 'antd';
import { navigate } from 'gatsby';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../components/layouts/default.layout';
import { Navigation } from '../components/navigation';
import { updateSummary } from '../state/actions';
import { IState } from '../state/types';
import * as Sentry from '@sentry/react';

const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const SummaryPage: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const summary = useSelector((state: IState) => state.summary || '') as string;

  const handleNext = async () => {
    form.submit();

    form
      .validateFields()
      .then(values => {
        dispatch(updateSummary(values?.summary));
        navigate(`/work-experience${window.location.search}`);
      })
      .catch(err => {
        Sentry.captureException('Failure when at summary (rb)', context => {
          context.setLevel(Severity.Fatal);
          context.setExtras({
            error: err,
            error_fields: err?.errorFields[0],
            error_name: err.name,
            error_message: err.message,
            stack_trace: err.stack
          });
          return context;
        });
      });
  };

  return (
    <DefaultLayout>
      <div style={s.header}>
        <Title level={2}>Summary</Title>
        <Paragraph>First, tell me about yourself</Paragraph>
      </div>

      <Divider />

      <Form form={form} initialValues={{ summary }}>
        <Form.Item name="summary">
          <TextArea
            placeholder="(Optional) I have 10 years of warehouse experience. I have been responsible for many projects including ..."
            style={{ minHeight: 200 }}
          />
        </Form.Item>
        <Paragraph type="secondary" style={{ marginTop: -10 }}>
          Add 2-3 points for employers to notice your resume more
        </Paragraph>
      </Form>

      <div style={s.header}>
        <Navigation
          step={2}
          goBackHandler={() => navigate(`/${window.location.search}`)}
          goNextHandler={handleNext}
        />
      </div>
    </DefaultLayout>
  );
};

export default SummaryPage;

const s: Stylesheet = {
  header: {
    textAlign: 'center',
    marginTop: 50
  }
};
